.root-query-pane-container {
    display: flex;
    flex-grow:1;
    flex-direction: column;
    box-shadow: 0px 0px 10px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    margin: 10% 10% 10% 10%;
    max-width: 1070px;

}

.query-pane-header-container {
    display: flex;
    flex-grow: 0;
    padding: max(2%, 25px);
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    font-family: var(--title-font);

    /* Rounded Slate Gray Background */

    background-color: var(--query-pane-header-main-color);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.query-pane-header-container-center {
    display: flex;
    padding: 2mm;
    margin: 0;
    border-radius: 5px;
    background-color: var(--query-pane-header-rounded-background-color);
}

.query-pane-bottom-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    background-color: var(--query-pane-search-container-main-color);
}

.query-pane-footer-container {
    display: flex;
    width: 100%;
    height: 5mm;
    background-color: var(--query-pane-footer-container-main-color);
    justify-content: center;
    flex-wrap: nowrap;
    color: var(--query-pane-footer-container-alt-color);
}

.query-pane-footer-container:hover {
    display: flex;
    width: 100%;
    height: 5mm;
    background-color: var(--query-pane-footer-container-alt-color);
    justify-content: center;
    flex-wrap: nowrap;
    color:var(--query-pane-footer-container-main-color);
    border: thin solid var(--query-pane-footer-container-main-color);
}


.query-pane-header-container h6{
    margin-bottom: 0;
    color: var(--query-pane-header-text-color);
    padding-right: 2mm;
    border-right: 1px solid var(--query-pane-header-title-divider-color);

}

.query-pane-bottom-center-root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative; /* Important for positioning the child overlay absolute "relative" to the parent (this container) */
}

.query-pane-bottom-center {
    display:flex;
    max-width: 750px; /* A multiple of the queryField size  */
    border-radius: 5px;
    padding: 2mm 2mm 0mm 2mm;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.query-pane-bottom-center-overlay {
    position: absolute; /* Important for having this element overlap on top of other elements */
    z-index: 1; /* Make this element the higher one on the z-axis */
    height: 100%;
    width: 100%;
    opacity: 0.9;
    background-color: var( --query-pane-search-container-overlay-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--query-pane-search-overlay-color);
    font-family: var(--paragraph-font);
    font-weight: bold;
    font-size: 1.2em;
}


.query-pane-bottom-button {
    display:flex;
    border-radius: 5px;
    padding: 0mm 2mm 2mm 2mm;
    justify-content: center;
    font-family: var(--button-font);
}

.query-pane-bottom-button button {
    background-color: var(--query-pane-search-button-color);
    border-radius: 15px;
	color: var(--query-pane-search-button-text-color);
	border: none;
    padding: 1mm 3mm 1mm 3mm;
    margin: 2mm 1mm 2mm 1mm;
}

/* Note bottom text boxes  */

.query-pane-bottom-text {
    display: flex;
    flex-direction: column;
    border: thin solid var(--query-field-note-box-border-color);
    font-size: small;
    padding: 3mm;
    margin: 2mm 2mm 4mm 2mm;
    border-radius: 2mm;
    width: min(550px, 85%);
}


#query-pane-bottom-text-header {
    display: flex;
    margin: 0;
    padding: 0;
}

#query-pane-bottom-text-header > p {
    margin: 2px 0px 2px 0px;
    padding: 3px 9px 3px 9px;
    border-radius: 5px;
    background-color: var(--query-field-note-box-background-color);
    font-size: small;
    font-weight: bold;
    color: var(--query-field-note-box-font-color);
}

#query-pane-bottom-text-body {
    display: flex;
    margin: 0;
    padding: 0;
}

#query-pane-bottom-text-body > p {
    margin: 2px 0px 2px 0px;
    padding: 3px 5px 3px 5px;
    font-size: 0.95em;
    color: var(--query-field-note-box-font-color);
}

