.root-control-panel-container {
    display: flex;
    justify-content: center;
    background-color: var(--control-panel-main-color);
}


.control-panel-center-container {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: flex-end;
    border-left: thin solid var(--control-panel-divider-color);
    border-right: thin solid var(--control-panel-divider-color);
    padding: 0mm 2mm 0mm 2mm;
    margin: 2mm 0 2mm 0;
}

.control-panel-center-container button {
    background: inherit;
	color: var(--control-panel-button-color);
	border: none;
	padding: 0;
    font: inherit;
	cursor: pointer;
    padding: 1mm 1mm 1mm 1mm;
    margin: 0mm 1mm 0mm 1mm;
}

.control-panel-center-container button:hover {
	background-color: var(--control-panel-button-alt-color);
	border: none;
	padding: 0;
    font: inherit;
	cursor: pointer;
    padding: 1mm 2mm 1mm 2mm;
    margin: 0mm 1mm 0mm 1mm;
    border-radius: 1mm;
}

button:hover .expanding-button {
    height: 7mm;
}