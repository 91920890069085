.root-query-result-pane-container {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    background-color: var(--qrp-main-color);
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    container-type: inline-size;  /*needed for use of media queries on the width of this container */

}

/* Summary container CSS */

[class*="qrp-summary-container"] {
    flex-grow: 1;
    display: flex;
    border-left: 1px solid var(--qrp-summary-divider-color);
    margin: 11px 11px 11px 11px;
    padding: 7px 11px 7px 11px;
    flex-direction: column;
    align-self: flex-start;
    width: 450px;
    max-width: 90%;
}

.qrp-summary-header h6 {
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    padding: 1mm; 
    color: var(--qrp-header-text-color);
    border-radius: 2px;
    background-color: var(--qrp-header-rounded-background-color);
    font-family: var(--sub-header-font);
}

.qrp-summary-body {
    display: flex;
    flex-grow: 1;
    color: var(--qrp-summary-text-color);
    font-size: small;
    flex-direction: column;
    padding: 2mm 2mm 2mm 2mm;

}

.qrp-summary-body h6 {
    font-family: var(--sub-header-font);
}

.qrp-summary-body p{
    margin-bottom: 1mm;
    padding-left: 2mm;
    font-family: var(--paragraph-font);
}

.qrp-summary-body div {
    font-family: var(--paragraph-font);
}

.qrp-summary-body-button-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    margin-top: 8px;   
}

.qrp-summary-body button{
    background-color: var(--qrp-summary-feedback-button-color);
    border-radius: 15px;
	color: var(--qrp-summary-feedback-button-text-color);
	border: none;
    padding: 1mm 3mm 1mm 3mm;
    margin: 0mm 1mm 0mm 1mm;
    font-family: var(--button-font);
}
.qrp-summary-body button:hover {
    background-color: var(--qrp-summary-feedback-button-alt-color);
}

.qrp-summary-body button a {
    text-decoration: none;
    color: var(--qrp-summary-feedback-button-text-color);
}

/* Log list Container CSS */

[class*="qrp-log-list-container"]{
    display: flex;
    border: thin solid var(--qrp-log-list-border-color);
    flex-direction: column;
    background-color: var(--qrp-log-list-main-color);
    margin: 11px 11px 11px 11px;
    padding: 7px 11px 7px 11px;
    border-radius: 6px;
    height: 320px;
    width: 450px; /* The width is hard-coded here for Firefox browsers that don't support container media queries*/
    flex-grow: 0;
}

@container (min-width: 936px) { /* 936 is the size of each child container (450px x2) plus the margin (11px x2) and padding (7px x2)*/

    .qrp-summary-container {
        max-width: min(450px, 50%);
    }

    .qrp-log-list-container {
        max-width: min(450px, 50%);
    }
        
}
    
@container (max-width: 936px) {

    .qrp-summary-container {
        max-width: 75%;
        min-width: 75%;
    }

    .qrp-log-list-container {
        min-width: min(430px, 90%);
        max-width: min(430px, 90%);
    }
            
}

.qrp-log-list-header {
    border-bottom: thin solid var(--qrp-log-list-divider-color);
    color: var(--qrp-log-list-text-color);
    flex-grow: 0;
    padding-bottom: 1mm;
    font-family: var(--sub-header-font);
}

.qrp-log-list-footer {
    border-top: thin solid var(--qrp-log-list-divider-color);
    color: var(--qrp-log-list-text-color);
    font-size: small;
    flex-grow: 0;
    padding-top: 1mm;
    font-family: var(--paragraph-font);
}

.qrp-log-list-body {
    overflow-y: scroll;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

/* Log Graphic CSS */

.qrp-log-graphic {
    display: flex;
    padding: 1mm;
    border-radius: 8px;
    margin: 2mm;
    background-color: var(--qrp-log-graphic-main-color);
    box-shadow: 0px 0px 4px;
    font-family: var(--log-graphic-font);
}

.qrp-log-graphic-column {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    min-width: 10%;
    justify-content: flex-start;
    color: var(--qrp-log-graphic-caret-color);
    padding: 0;
}

.qrp-log-graphic-body {
    flex-direction: column;
    flex-grow: 1;
}

.qrp-log-graphic-body-row p {
    display: flex;
    font-size: x-small;
    padding-left: 0mm;
    margin-bottom: 6px;
    width: 100%;
    color:var(--qrp-log-graphic-text-color);
}

.qrp-log-graphic-body-row p mark {
    margin: 0;
    padding: 0;
}

.qrp-log-graphic-column div {
    flex-wrap: nowrap;
    border-left: thin solid var(--qrp-log-graphic-vertical-line-color);
    border-bottom: thin solid var(--qrp-log-graphic-vertical-line-color);
    flex-grow: 1;
    margin: 0 40% 33% 50%;
    padding-top: 0;
}