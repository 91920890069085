html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-width: 549px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body div#root {
  display: flex;
  height: 100%;
  width: 100%;
}

@import url("https://fonts.googleapis.com/css?family=Roboto");

/* Global custom property definitions */

:root {
  /* Font custom properties */
  --title-font: "Roboto";
  --header-font: "Roboto";
  --field-font: "Roboto";
  --sub-header-font: "Roboto";
  --button-font: "Roboto";
  --paragraph-font: "Roboto";
  --table-font: "Roboto";
  --fine-print-font: "Roboto";
  --log-graphic-font: "Roboto";

  /* Color custom properties */

  /* Footer and Header components */
  --header-main-color: gainsboro; 
  --header-text-color: gainsboro; 
  --header-rounded-background-color: rgb(2,94,145);
  --header-title-icon-divider-color: gainsboro;
  --footer-main-color: gainsboro;
  --footer-text-color: gainsboro;
  --footer-text-alt-color:   rgb(48,48,48);
  --error-page-paragraph-color: gainsboro;
  --error-page-header-color: goldenrod;
  --error-page-header-rounded-color: black;

  /* QueryPane component and children */
  --control-panel-main-color: rgb(48, 48, 48);
  --control-panel-divider-color: rgb(34, 34, 34);
  --control-panel-button-color: gainsboro;
  --control-panel-button-alt-color: rgb(75, 75, 75);

  --query-field-main-color: rgb(48,48,48);
  --query-field-text-color: white;
  --query-field-divider-color: rgb(24, 24, 24);
  --query-field-info-icon-color: black;
  --query-field-info-icon-alt-color: gray;
  --query-field-validated-icon-color: black;
  --query-field-invalidated-icon-color: goldenrod;
  --query-field-note-box-border-color: rgb(20,20,20);
  --query-field-note-box-background-color:rgb(48,48,48);
  --query-field-note-box-font-color: silver;
  --query-field-note-box-icon-color: goldenrod;

  --query-pane-header-main-color: rgb(48,48,48);
  --query-pane-header-rounded-background-color: rgb(34,34, 34);
  --query-pane-header-text-color: gainsboro;
  --query-pane-header-title-divider-color: gray;
  --query-pane-search-container-main-color: rgb(34, 34, 34);
  --query-pane-search-container-overlay-color: rgb(20, 20, 20);
  --query-pane-search-overlay-color: blue;
  --query-pane-search-overlay-font-color: beige;
  --query-pane-search-button-color: rgb(48, 48, 48);
  --query-pane-search-button-text-color: white;
  --query-pane-footer-container-main-color: rgb(34, 34, 34);
  --query-pane-footer-container-alt-color: rgb(48, 48, 48);

  /* QueryHelpPane Component */

  --query-help-pane-main-color: rgb(48, 48, 48);
  --query-help-pane-border-color: black;
  --query-help-pane-text-color: white; 
  --query-help-pane-header-divider-color: rgb(34,34, 34);

  /* QueryResultPane Component */

  --qrp-main-color: rgb(34, 34, 34);
  --qrp-summary-divider-color: rgb(24, 24, 24);
  --qrp-header-rounded-background-color: rgb(24, 24, 24);
  --qrp-header-text-color: white;
  --qrp-summary-text-color: white;
  --qrp-summary-feedback-button-color: rgb(48, 48, 48);
  --qrp-summary-feedback-button-alt-color: rgb(75, 75, 75);
  --qrp-summary-feedback-button-text-color: gainsboro;
  --qrp-log-list-main-color: rgb(24, 24, 24);
  --qrp-log-list-border-color: black;
  --qrp-log-list-divider-color: rgb(34, 34, 34);
  --qrp-log-list-text-color: white;
  --qrp-log-graphic-main-color: rgb(34, 34, 34);
  --qrp-log-graphic-caret-color: rgb(140, 140, 140);
  --qrp-log-graphic-text-color: rgb(140, 140, 140);
  --qrp-log-graphic-vertical-line-color: silver;


  /* MainPane component */

  --main-pane-main-color:  rgb(24, 24, 24);

}