.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-height: 4mm;
    max-width: 100%;
    background-color: var(--footer-main-color);
    color: var(--footer-text-color);

}

.footer-item-edge {
    font-family: var(--fine-print-font);
    color: black;
    max-height: 100%;
    max-width: 100%;
    flex-grow: 0;
   
}

.footer-item-edge a{
    text-decoration: none;
    font-size: x-small;
    display: flex;
    padding: 0 2mm 0 2mm;
    box-shadow: 0 0 1px dimgray;
    color: black;
}

.footer-item-edge a:hover{
    color: var(--footer-text-alt-color);
}
