.root-main-container{
    /* Do not set height to 100% or  */
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto; /*allow for scrollable content along the y-axis*/
    background-color: var(--main-pane-main-color);
    justify-content: flex-start;
    align-items: center;
}

