.header-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-height: 12mm;
    max-width: 100%;
    background-color: var(--header-main-color);
}

[class*="header-item"] {
    /* base header item properties */
    flex-grow: 1; /* This defines the ability for a flex item to grow if necessary */
    flex-basis: 0; /* the extra space around content isn’t factored in. */
}

.header-item-edge {
    display: flex;
    justify-content: flex-start;
}

.header-item-centered {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    color: var(--header-text-color);
    white-space: nowrap;
    flex-grow: 0;
}

.corp-header-logo {
    display: flex;
    padding: 1mm 1mm 1mm 1mm;
    max-height: 100%;
    max-width: 100px; /* Must be enforced on Safari since the image covers entirety of the container. */
    object-fit: contain; /* preserves the corporate logo aspect ratio and sizing to fit within its container */
    align-self: flex-start;
    flex-grow: 0;
}

.header-item-rounded-background {
    display: flex;
    margin: 2mm 0mm 2mm 0mm;
    padding: 1mm 3mm 1mm 3mm;
    background-color:  var(--header-rounded-background-color);
    border-radius: 5px;
    flex-wrap: nowrap;
}

.header-item-centered-h4{
    padding: 0;
    margin: 0;
    font-family: var(--title-font);
}

.header-title-symbol {
    align-self: center;
    max-height: 5mm;
    padding-left: 2mm;
    margin-left: 2mm;
    border-left: 2px solid var(--header-title-icon-divider-color);
}

