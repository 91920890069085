.error-page-root-container {
    /* Do not set height to 100% or  */
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto; /*allow for scrollable content along the y-axis*/
    background-color: var(--main-pane-main-color);
    justify-content: flex-start;

}

.error-page-container {
    color: white;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error-page-header {
    display: flex;
    font-family: var(--header-font);
    border-radius: 5px;
    background-color: var(--error-page-header-rounded-color);
    padding: 2mm;
    margin: 4mm;
}

.error-page-header > h1 { 
    margin: 0;
    color: var(--error-page-header-color);
}

.error-page-body > p {
    font-family: var(--paragraph-font);
    color: var(--error-page-paragraph-color);
    margin: 0;
    text-align: center;
}