.root-query-help-pane-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 2mm;
  padding: 2mm;
  background-color: var(--query-help-pane-main-color);
  border-radius: 6px;
  border: thin solid var(--query-help-pane-border-color);
  max-height: 100%;
  max-width: 100%;
}

.query-help-pane-header {
  display: flex;
  flex-grow: 0;
  padding-left: 2mm;
  padding: 1mm;
}

.query-help-pane-header h6{
  display: flex;
  flex-grow: 0;
  padding-bottom: 1.5mm;
  color: var(--query-help-pane-text-color);
  font-size: small;
  font-weight: bold;
  border-bottom: thin solid var(--query-help-pane-header-divider-color);
  font-family: var(--sub-header-font);
}

.query-help-pane-body {
  display: flex;
  color: var(--query-help-pane-text-color);
  overflow-y: auto;
  font-size: small;
  margin: 0;
  padding-left: 2mm;
  padding-right: 2mm;
  padding-bottom: 2mm;
  font-family: var(--paragraph-font);
}

.query-help-pane-body table {
  margin-left: min(10%, 10mm);
  margin-right: min(10%, 10mm);
  font-size: small;
  color: var(--query-help-pane-text-color);;
  font-family: var(--table-font);
}