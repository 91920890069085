.query-field-container {
    display: flex;
    flex-wrap: nowrap;
    margin: 2mm 2mm 2mm 2mm;
    max-width: 100%;
    width: 350px;
    font-family: var(--field-font);
}

.query-field-container input {
    appearance: none;
    outline: 0;
    border: 0;
    border-radius: 0;
    color: var(--query-field-text-color);
    background-color: var(--query-field-main-color);
    padding: 5px;
    flex-grow: 1;
    font-size: small;
}

.query-field-container select {
    border-radius: 0;
    outline: 0;
    border: 0;
    color: var(--query-field-text-color);
    font-size: small;
    flex-grow: 1;
    padding: 5px;
    padding-bottom: 7px;
    margin: 0;
    -moz-appearance: none; 
    -webkit-appearance: none; 
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='dimgray'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-color: var(--query-field-main-color);
    background-size: 12px;
    background-position: right 10px top 14px;
    background-repeat: no-repeat;
}

.label-half-pill {
    display: flex;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    outline: 0;
    border: 0;
    color: var(--query-field-text-color);
    background-color: var(--query-field-main-color);
    padding-left: 4mm;
    align-content: center;
    align-items: center;
}

.label-half-pill p{
    white-space: nowrap;
    text-align: center;
    font-size: small;
    margin: 0;
    border-right: thin solid var(--query-field-divider-color);
    padding-right: 2mm; 
}

.status-half-pill {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    outline: 0;
    border: 0;
    color: black;
    background-color: var(--query-field-main-color);
    padding: 5px;
    padding-right: 4mm;
    flex-grow: 0;
}

.status-half-pill:hover {
    color: gray;
}

/* for Chrome and other Browsers which support the auto-fill feature for input elements*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px var(--query-field-main-color) inset !important;
    -webkit-text-fill-color: var(--query-field-text-color) !important;
}


